import EnvConfig from "../types/config";

const envConfig: EnvConfig = {
  campaignCode: process.env['REACT_APP_CAMPAIGN_ID'] ? parseInt(process.env['REACT_APP_CAMPAIGN_ID']) : (window?.environment?.REACT_APP_CAMPAIGN_ID || -1),
  websiteTitle: process.env['REACT_APP_WEBSITE_TITLE'] || window.environment.REACT_APP_WEBSITE_TITLE,
  websiteFontFamily: process.env['REACT_APP_WEBSITE_FONTFAMILY'] || window.environment.REACT_APP_WEBSITE_FONTFAMILY,
  logoHeader: process.env['REACT_APP_LOGO_HEADER'] || window.environment.REACT_APP_LOGO_HEADER,
  programTitle: process.env['REACT_APP_BODY_PROGRAM_TITLE'] || window.environment.REACT_APP_BODY_PROGRAM_TITLE,
  bodyImage: process.env['REACT_APP_BODY_IMAGE'] || window.environment.REACT_APP_BODY_IMAGE,
  bodyLabelInfoText: process.env['REACT_APP_BODY_LABEL_INFO_TEXT'] || window.environment.REACT_APP_BODY_LABEL_INFO_TEXT,
  sponsorApi: process.env['REACT_APP_SPONSOR_API'] || window.environment.REACT_APP_SPONSOR_API,  
}

export default envConfig