const ProgramDescription = () => (  
    <div className="block pt-3 pb-3 text-lg text-gray-900 font-normal  leading-6 mt-8 mb-8">
      Este programa é especializado em vacinas voltado para os colaboradores que possuem o Benefício Farmácia da Funcional, com objetivo de proporcionar acesso à vacinação contra influenza.
      <br /><br />
      Para saber mais <a href="/fac" className="text-green-funcional font-medium">clique aqui</a> ou procure o RH da sua empresa.
      <br /><br />
      Consulte se seu benefício está apto ao programa a seguir:
    </div>  
)

export default ProgramDescription