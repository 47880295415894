import { IconMap } from ".";

export const CardMap = ({...props} ): JSX.Element => {
  return (    
      <div className="flex-col max-w-7xl mx-8 items-start gap-[4px] pt-[16px] pb-[8px] px-[16px] relative bg-colors-neutral-white border-b [border-bottom-style:solid] border-colors-neutral-300">
        <div className="pt-0 pb-[8px] px-0 flex items-center justify-between relative self-stretch w-full flex-[0_0_auto]">
          <div className="relative w-fit text-md text-slate-900 font-bold text-center whitespace-nowrap">
          {props.tradeName}
          </div>
        </div>
        <p className="flex self-stretch text-sm text-gray-400">
        {props.address + ', ' + props.district}
        </p>
        <div className="flex items-center my-2 justify-between relative self-stretch w-full text-sm text-gray-400">          
          {props.telephone}                       
          <div className="flex">
            <a href={props.linkMap} target="_blank" rel="noreferrer" className="mr-2 tracking-wide text-lime-600 font-semibold">Ver mapa</a>            
            <IconMap />          
          </div>
        </div>
      </div>      
  );
};