// Importe a função useQuery do 'react-query' e o gql do 'graphql-request'
import { useQuery, UseQueryOptions } from 'react-query';
import { gql, GraphQLClient } from 'graphql-request';
import envConfig from '../config/environmet-configs';

// Defina a URL da API diretamente
const apiURL = envConfig.sponsorApi || "";

// Interface para os dados da query
export interface HasBeneficiaryAdhesionData {
  hasBeneficiaryAdhesion: {
    BeneficiaryId: string;
    GroupId: string;
    isCardActive: boolean;
    isCampaignActive: boolean;
  };
}

// Interface para as variáveis da query
interface HasBeneficiaryAdhesionVariables {
  campaignId: string;
  cardNumber: string;  
}

// Função hook para a query GraphQL
export function useHasBeneficiaryAdhesionQuery(
  { campaignId, cardNumber }: HasBeneficiaryAdhesionVariables,
  options?: UseQueryOptions<HasBeneficiaryAdhesionData>
) {  
  return useQuery<HasBeneficiaryAdhesionData>(
    ['hasBeneficiaryAdhesion', campaignId, cardNumber],
    async () => {      
      // const resultGenerateToken = await GetToken();      
      const client = new GraphQLClient(apiURL);
      
      const data = await client.request(
        gql`
          query hasBeneficiaryAdhesion_BeneficiaryRegistred(
            $campaignId: ID!
            $cardNumber: String!
          ) {
            hasBeneficiaryAdhesion(
              input: {
                campaignId: $campaignId
                cardNumber: $cardNumber
              }
            ) {
              BeneficiaryId
              GroupId
              isCardActive
              isCampaignActive
            }
          }
        `,
        {
          campaignId,
          cardNumber,
        }
      );
      return data as HasBeneficiaryAdhesionData;
    },
    {
      ...options,
      enabled: false, // A execução será controlada manualmente
    }
  );
}
