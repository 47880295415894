import Footer from "./Footer/Footer";
import CustomHeader from "./Header/Header";
import CustomMenu from "./Header/Menu";

const Home = () => (  
    <div className="font-[Roboto]">            
      <CustomHeader></CustomHeader>
      <CustomMenu></CustomMenu>            
      <Footer></Footer>      
    </div>  
)

export default Home