import { ChangeEvent, useState } from 'react';
import { useQueryEstablishment } from '../../hooks/useQueryEstablishment';
import { CardMap, SearchImage, GifLoading } from '../../components';
import envConfig from '../../config/environmet-configs';

const EstablishmentSearch = () => {
  const [zipcode, setZipcode] = useState('');
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);
  
  const query = useQueryEstablishment({
    campaignId: String(envConfig.campaignCode || -1),
    zipCode: zipcode,
  });

  const handleZipCodeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setZipcode(event.target.value.replace(/[^0-9/]/g, ""));
  }

  const handleSearchOnEnter = async (event: React.KeyboardEvent<HTMLInputElement>) => {
      setShowError(false); 
      if (event.key === 'Enter') {
        await SearchEstablishment()
      }
  };

  const handleSearchOnButtonClick = async () => await SearchEstablishment()   

  async function SearchEstablishment()
  {
    try {      
      if (zipcode.trim().length === 8) {
        setShowError(false);            
        setLoading(true)
        await query.refetch();
        setLoading(false);
      } else {
        setShowError(true);
      }
    }    
    catch {
      setLoading(false)
    }
  }

  return (
    <div className="w-full text-center p-2">
      <span className="block">
        <p className="font-bold text-3xl m-4">Buscar redes credenciadas</p>
        <label htmlFor="zipcode" className="block text-gray-400 text-lg m-4">Busque por um cep para consultar as redes credenciadas ao programa de vacinação</label>
        <label className="inline-flex flex-row border-2 w-96 p-1 rounded-lg mt-4">          
          <input
            type="text"
            id="zipcode"
            placeholder="Digite o CEP sem o traço, ex.: 01234567"
            className="w-full focus:outline-none"
            value={zipcode}
            onChange={handleZipCodeChange}
            maxLength={8}
            onKeyUp={handleSearchOnEnter}            
          />
        <button
            type="button"
            onClick={handleSearchOnButtonClick}          
            className="inline-flex flex-row hover:bg-gray-200 focus:bg-gray-300 rounded-lg sm:w-auto text-center"
          ><SearchImage /></button>     
        </label>        
          {showError && <p className="text-red-500">O CEP precisa ter 8 caracteres numéricos.</p>}
      </span>
      <div className="inline-block w-1/2 m-11 border-t border-t-green-funcional shadow-lg">        
        {query.data && query.data.queryEstablishment && query.data.queryEstablishment.length > 0 && (
          query.data.queryEstablishment.map((establishment) => (
            <CardMap
              key={establishment.id}
              tradeName={establishment.name}
              address={establishment.address}
              district={establishment.neighborhood}
              telephone={establishment.phone}
              linkMap={`https://maps.google.com/maps?q=${establishment.latitude},${establishment.longitude}`}
            />
          ))
        )}        
      </div>
      {query?.data?.queryEstablishment.length === 0 && <div className="mb-10"><label className="text-gray-400 font-medium"><p>Nenhuma rede credenciada encontrada</p></label></div>}
      {loading && <GifLoading />}
    </div>
  );
};

export default EstablishmentSearch;
