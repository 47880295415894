import { useQuery, UseQueryOptions } from 'react-query';
import { gql, GraphQLClient } from 'graphql-request';
import envConfig from '../config/environmet-configs';

// Defina a URL da API diretamente
const apiURL = envConfig.sponsorApi || "";

// Interface para os dados da query
export interface EstablishmentData {
    queryEstablishment: {
      id: string;
      campaignId: string;
      establishmentId: string;
      name: string;
      address: string;
      zipCode: string;
      phone: string;
      neighborhood: string;
      city: string;
      state: string;
      latitude: string;
      longitude: string;
    }[];
  }
  

// Interface para as variáveis da query
interface QueryEstablishmentVariables {
  campaignId: string;
  zipCode: string;
}

// Função hook para a query GraphQL
export function useQueryEstablishment(
  { campaignId, zipCode }: QueryEstablishmentVariables,
  options?: UseQueryOptions<EstablishmentData>
) {  
  return useQuery<EstablishmentData>(
    ['queryEstablishment', campaignId, zipCode],
    async () => {
      const client = new GraphQLClient(apiURL);

      const data = await client.request(
        gql`
          query QueryEstablishment($campaignId: ID!, $zipCode: String!) {
            queryEstablishment(
              input: {
                campaignId: $campaignId
                zipCode: $zipCode
              }
            ) {
              id
              campaignId
              establishmentId
              name
              address
              zipCode
              phone
              neighborhood
              city
              state
              latitude
              longitude
            }
          }
        `,
        {
          campaignId,
          zipCode,
        }
      );
      return data as EstablishmentData;
    },
    {
      ...options,
      enabled: false, // A execução será controlada manualmente
    }
  );
}
