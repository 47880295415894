import envConfig  from "../../../config/environmet-configs";

const CustomHeader = () => (
  <div className="m-5 flex align-middle justify-center">
    <a href="/" >
      <img 
        alt='Logo'
        width='126px'
        height='36px'  
        src={envConfig.logoHeader}>        
      </img>
    </a>
  </div>
)

export default CustomHeader