import parse  from 'html-react-parser';

const CustomFooter = ({ ...props }) => (
    <div className="inline-flex flex-col justify-between gap-2">
      <label className="text-sm text-gray-400 block">{props.title}</label>
      <div>        
        {props.links.map((element: string, index: number) => 
          <label className="text-sm text-gray-800 font-bold block mt-2">{parse(element)}</label>)}
      </div>
    </div>
)

export default CustomFooter
