import envConfig  from "../../config/environmet-configs";
import CardBox from "./CardBox";

const CustomBody = () => (
  <div>
      <div style={{backgroundImage: `url(${envConfig.bodyImage})`}} className="flex mt-12 mr-auto mb-12 ml-auto bg-[length:100%_140%] rounded-[30px] w-[1268px] h-[593px] " >
        <div className="border-green-funcional w-5 h-40 border rounded-tl-[30px]  -translate-x-[18px] translate-y-[18px] -z-10"></div>        
          <CardBox></CardBox>
        <div className="border-green-funcional w-60 h-40 border rounded-tl-[30px] rounded-br-[30px] translate-x-[183%] translate-y-[458px]"></div>
      </div>             
  </div>
)

export default CustomBody