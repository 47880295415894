import { CustomFooter } from "../../../components"

const ferramentas = ['<a href="/establishment">Rede Credenciada</a>']
const recursos = ['<a href="/fac">Perguntas Frequentes</a>']
const contato = ['Contato RH', '(11) 2198-4451 - Atendimento Telefônico e WhatsApp', '<a href="https://beneficiario.funcionalcard.com.br/Beneficiario/Login">Chat - Através da área logada do portal do beneficiário</a>', '<a href="mailto:sacbeneficiario@funcionalcorp.com.br">E-mail: sacbeneficiario@funcionalcorp.com.br</a>']
const centraAtendimento = ['0800-970-9020', '<a href="mailto:suporte@funcionalcorp.com.br">suporte@funcionalcorp.com.br</a>']


const Footer = () => (
  
  <div className="block bottom-0 w-full bg-gray-100 pt-6 pb-2">
    <div className="w-full grid-cols h-52 items-start align-middle justify-between inline-flex pl-10 pr-10 gap-3">
      <CustomFooter title='Funcional Heatlh Tech' links={contato}></CustomFooter>
      <CustomFooter title='Ferramentas' links={ferramentas}></CustomFooter>
      <CustomFooter title='Recursos' links={recursos}></CustomFooter>
      <CustomFooter title='Central de Atendimento' links={centraAtendimento}></CustomFooter>
    </div>
    <div className="text-sm text-center text-gray-400 border-t-2 pt-2">
      <label>Copyright © {new Date().getFullYear()} Funcional. All Right Reseved</label>
    </div>
  </div>
)

export default Footer