import { useState } from 'react'; 
import fac from '../../utils/fac.json'
import parse  from 'html-react-parser';

const Accordion = ({...props}) => { 
  return ( 
      <div className="border rounded-md" > 
          <button 
              className="w-full p-4 text-left font-black bg-gray-100 hover:bg-gray-200 transition duration-300"
              onClick={props.toggleAccordion} 
          > 
              {props.title} 
              <span className={`float-right transition-transform duration-300`}> 
              {props.isOpen ? '-' : '+'}               
              </span> 
          </button> 
          {props.isOpen && ( 
              <div className="p-4 bg-gray-50 text-sm text-gray-600 leading-7"> 
                  {parse(props.data)}
              </div> 
          )} 
      </div> 
  ); 
}; 

const CommonQuestions = () => {
  const [accordions, setAccordion] = useState(fac.questions); 

  const toggleAccordion = (accordionkey: number) => { 
    const updatedAccordions = accordions.map((accord) => { 
        if (accord.key === accordionkey) { 
            return { ...accord, isOpen: !accord.isOpen }; 
        } else { 
            return { ...accord, isOpen: false }; 
        } 
    }); 

    setAccordion(updatedAccordions); 
  }; 

  return ( 
    <div>       
        <div className="p-2 m-8 text-center">
            <h2 className="font-bold text-2xl mt-2 mb-2">Perguntas Frequentes</h2>
            <span className="text-gray-500 text-sm">
              <p className="m-4">Confira as respostas para as dúvidas mais frequentes.</p>
              <p className="mt-4 mb-12">Caso não encontre a resposta para a sua pergunta, envie um e-mail para a central de atendimento<br /> do programa: <a className="text-green-funcional font-bold" href="mailto:acare-br@abbott.com">acare-br@abbott.com</a> ou entre em contato através do 0800 970 9020, das 8 às 18 horas,<br /> de segunda a sexta-feira (exceto feriados)</p>
            </span>
            <div className="inline-table text-left w-1/2">
              {accordions.map((accordion) => ( 
                  <Accordion 
                      key={accordion.key}
                      title={accordion.title}
                      data={accordion.data}
                      isOpen={accordion.isOpen}
                      toggleAccordion={() => toggleAccordion(accordion.key)}
                  /> 
              ))} 
            </div>
        </div> 
    </div> 
  )
};

export default CommonQuestions
