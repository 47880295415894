export const EmojiSad = () => (
  <div className="my-0 mx-auto bg-red-100 rounded-3xl p-3">    
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="vuesax/bulk/emoji-sad">
        <g id="emoji-sad">
          <path id="Vector" opacity="0.4" d="M43.48 15.6186V15.62V32.38C43.48 35.9206 42.4266 38.6863 40.5464 40.5664C38.6663 42.4466 35.9006 43.5 32.36 43.5H15.62C12.0794 43.5 9.31384 42.4466 7.43377 40.5642C5.55364 38.6817 4.5 35.9109 4.5 32.36V15.62C4.5 12.0794 5.55343 9.31368 7.43355 7.43355C9.31368 5.55343 12.0794 4.5 15.62 4.5H32.38C35.9207 4.5 38.6862 5.55347 40.5637 7.43333C42.4411 9.31309 43.4897 12.0784 43.48 15.6186Z" fill="#E22849" stroke="#E22849"/>
          <path id="Vector_2" d="M21.2799 18.5C21.0253 18.5 20.7747 18.4077 20.5734 18.2065C18.9582 16.5912 16.3216 16.5912 14.7063 18.2064C14.3216 18.5912 13.6782 18.5912 13.2934 18.2064C12.9087 17.8217 12.9087 17.1783 13.2934 16.7936C15.6772 14.4098 19.581 14.4076 21.9869 16.7941C22.3711 17.1789 22.3709 17.8219 21.9863 18.2064C21.785 18.4077 21.5344 18.5 21.2799 18.5Z" fill="#E22849" stroke="#E22849"/>
          <path id="Vector_3" d="M26.0137 16.7936C28.3974 14.4098 32.3012 14.4076 34.7071 16.7941C35.0913 17.1789 35.0911 17.8219 34.7065 18.2064C34.3218 18.5912 33.6784 18.5912 33.2937 18.2064C31.6784 16.5912 29.0418 16.5912 27.4265 18.2064L27.4205 18.2125L27.4146 18.2188C27.2421 18.4036 27.0018 18.5 26.7201 18.5C26.4656 18.5 26.2149 18.4077 26.0137 18.2064L25.6601 18.56L26.0137 18.2064C25.6289 17.8217 25.6289 17.1783 26.0137 16.7936Z" fill="#E22849" stroke="#E22849"/>
          <path id="Vector_4" d="M24.0002 23.84C18.6202 23.84 14.2402 28.22 14.2402 33.6C14.2402 35 15.3802 36.16 16.7802 36.16H31.1802C32.5802 36.16 33.7202 35.02 33.7202 33.6C33.7602 28.22 29.3802 23.84 24.0002 23.84Z" fill="#E22849"/>
        </g>
      </g>
    </svg>
  </div>
)

export const EmojiHappy = () => (
  <div className="my-0 mx-auto bg-green-200 rounded-3xl p-3">    
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="vuesax/bulk/emoji-happy">
        <g id="emoji-happy">
          <path id="Vector" opacity="0.4" d="M43.48 15.6186V15.62V32.38C43.48 35.9206 42.4266 38.6863 40.5464 40.5664C38.6663 42.4466 35.9006 43.5 32.36 43.5H15.62C12.0794 43.5 9.31384 42.4466 7.43377 40.5642C5.55364 38.6817 4.5 35.9109 4.5 32.36V15.62C4.5 12.0794 5.55343 9.31368 7.43355 7.43355C9.31368 5.55343 12.0794 4.5 15.62 4.5H32.38C35.9207 4.5 38.6862 5.55347 40.5637 7.43333C42.4411 9.31309 43.4897 12.0784 43.48 15.6186Z" fill="#00853C" stroke="#00853C"/>
          <path id="Vector_2" d="M34.2602 16.5C34.2602 18.3004 32.8007 19.76 31.0002 19.76C29.1998 19.76 27.7402 18.3004 27.7402 16.5C27.7402 14.6995 29.1998 13.24 31.0002 13.24C32.8007 13.24 34.2602 14.6995 34.2602 16.5Z" fill="#00853C" stroke="#00853C"/>
          <path id="Vector_3" d="M20.2602 16.5C20.2602 18.3004 18.8007 19.76 17.0002 19.76C15.1998 19.76 13.7402 18.3004 13.7402 16.5C13.7402 14.6995 15.1998 13.24 17.0002 13.24C18.8007 13.24 20.2602 14.6995 20.2602 16.5Z" fill="#00853C" stroke="#00853C"/>
          <path id="Vector_4" d="M31.1998 25.8401H16.7998C15.3998 25.8401 14.2598 26.9801 14.2598 28.4001C14.2598 33.7801 18.6398 38.1601 24.0198 38.1601C29.3998 38.1601 33.7798 33.7801 33.7798 28.4001C33.7598 27.0001 32.5998 25.8401 31.1998 25.8401Z" fill="#00853C"/>
        </g>
      </g>
    </svg>
  </div>  
)