import { Home } from './pages';
import envConfig from './config/environmet-configs';

document.title= envConfig.websiteTitle || ''

function App() {
  return (    
    <div className="font-[Roboto]">            
      <Home></Home>
    </div>
  );
}

export default App;
