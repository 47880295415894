import { Link, Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { CustomBody, EstablishmentSearch, CommonQuestions } from '../..';

const CustomMenu = () => (
  <Router>
    <div className="bg-green-funcional grid grid-cols-3 h-14 text-center gap-10 ml-2 mr-2 content-center items-center px-10">
      <Link to="/" className="focus:bg-amber-50 rounded h-8 focus:border-b-2 focus:border-gray-500 focus:shadow">O Programa</Link>
      <Link to="/establishment" className="focus:bg-amber-50 rounded h-8 focus:border-b-2 focus:border-gray-500 focus:shadow">Redes Credenciadas</Link>
      <Link to="/fac" className="focus:bg-amber-50 rounded h-8 focus:border-b-2 focus:border-gray-500 focus:shadow">Perguntas Frequentes</Link>
   </div>
    <Routes>
      <Route path="/" element={<CustomBody />}></Route>
      <Route path="/establishment" element={<EstablishmentSearch />}></Route>
      <Route path="/fac" element={<CommonQuestions />}></Route>
    </Routes>
  </Router>  
)

export default CustomMenu
