import React, { ChangeEvent, useState } from 'react';
import { useHasBeneficiaryAdhesionQuery } from '../../hooks/useHasBeneficiaryAdhesionQuery';
import CustomLabelTitle from "./BodyProgramTitle";
import ProgramDescription from "./BodyProgramDescription";
import envConfig from "../../config/environmet-configs";
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import { EmojiHappy, EmojiSad, GifLoading } from '../../components';



const CardBox = () => {  
  const [open, setOpen] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);
  const [cardNumber, setCardNumber] = useState('');
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCardNumber(event.target.value.replace(/[^0-9/]/g, ""));
    setShowError(false)
  }

  const handleOpen = () => {        
    setOpen(!open)     
  }

  const handleOpenError = () => {            
    setOpenError(!openError)   
  }

  const navigate = useNavigate();

  function SuccessDialog() {
    return (
      <Dialog className="mt-10 mx-auto bg-slate-100 w-2/5 max-w-[500px] h-96 text-center shadow-lg" open={open} handler={handleValidarClick} placeholder={""}>        
        <DialogHeader className="mt-8"  placeholder={""}>        
          <EmojiHappy />
        </DialogHeader>
        <DialogBody  placeholder={""}>
          <h2 className="text-2xl font-semibold">Benefício Cadastrado</h2>
          <p className="text-lg">O beneficiário do cartão já está cadastrado no programa especial de vacinação</p>
        </DialogBody>
        <DialogFooter className="text-center"  placeholder={""}>
          <Button variant="text" onClick={handleOpen} className="my-0 mx-auto px-8 py-4 border-2"  placeholder={""}>
            <span>Fechar</span>
          </Button>
          <Button variant="text" onClick={() => navigate('/establishment')} className="my-0 mx-auto px-6 py-4 bg-green-funcional"  placeholder={""}>
            <span>Buscar redes credenciadas</span>
          </Button>
        </DialogFooter>
      </Dialog>
    )
  }

  function ErrorDialog() {
    return (
      <Dialog className="mt-10 mx-auto bg-slate-100 w-2/5 max-w-[500px] h-96 text-center shadow-lg" open={openError} handler={handleValidarClick}  placeholder={""}>        
        <DialogHeader className="mt-8"  placeholder={""}>        
          <EmojiSad />          
        </DialogHeader>
        <DialogBody placeholder={""}>          
          <h2 className="text-2xl font-semibold">Algo deu errado</h2>
          <p className="text-lg">Por favor, entre em contato com o seu RH para mais informações</p>
        </DialogBody>
        <DialogFooter className="text-center"  placeholder={""}>
          <Button variant="text" onClick={handleOpenError} className="my-0 mx-auto px-6 py-4 bg-green-funcional" placeholder={""}>
            <span>Ok</span>
          </Button>                    
        </DialogFooter>
      </Dialog>
    )
  }

  function showDialog() {     
    if (openError)
      return ErrorDialog()
    else 
      return SuccessDialog()
  }

  const query = useHasBeneficiaryAdhesionQuery({
    campaignId: String(envConfig.campaignCode || -1),
    cardNumber,
  });

  const handleValidarClick = async () => {    
    if (cardNumber.trim().length === 0) {    
      setShowError(true)
      return;
    }
    try {
      setLoading(true)
      const retornoApi = await query.refetch();
      setLoading(false)

      if(retornoApi.data?.hasBeneficiaryAdhesion.isCampaignActive && retornoApi.data?.hasBeneficiaryAdhesion.isCardActive){
        setOpen(!open);
      } else {
        setOpenError(!openError);
      }
    } catch {
      setLoading(false)
    }    
  };

  return (
    <>
    <form>
      <div className="bg-gray-100 block rounded-[40px] w-[580px] h-[497px] p-6 mt-11 ml-6 opacity-90">
        <div>
          <CustomLabelTitle />
        </div>
        <div>
          <ProgramDescription />
        </div>
        <label htmlFor="cardNumber">
          <b>{envConfig.bodyLabelInfoText}</b>
        </label>
        <span className="opacity-100">
          <input
            type="text"
            id="cardNumber"
            value={cardNumber}
            onChange={handleInputChange}
            className="bg-gray-50 border border-gray-300 border-l-2 border-l-green-funcional text-gray-900 text-lg rounded-md block w-full p-2.5 dark:text-gray-900 my-2 focus:outline-none focus:border-b-slate-700"
            placeholder="Número do cartão"
            autoComplete="off"
            maxLength={20}
            required
          />
          {showError && <label className="text-red-500">É preciso digitar um cartão antes de validar</label>}
          <button
            type="button"
            onClick={handleValidarClick}          
            className="bg-green-funcional float-right h-14 text-white hover:bg-gray-700 focus:bg-gray-700 font-medium rounded-lg text-sm sm:w-auto px-5 py-2.5 text-center"
          >Consultar          
          </button>        
        </span>
      </div>      
    </form>
      {showDialog()}   
      {loading && <GifLoading />}   
    </>
  );
};

export default CardBox;
